import * as React from 'react';
import './App.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Navigate
} from "react-router-dom";
import { withTranslation } from 'react-i18next';

//components
import Alert from './Components/Alert';

// Redux
import { useAppDispatch } from './Hooks/customSelector';
import { setUser } from './Redux/Reducers/userReducer';

// Models
import User from './Models/User';

// *****Pages******* 

// Core
import Admin from './Core/Admin';
import Auth from './Core/Auth';

// Track
import Track from './Pages/Track';

// 404 
import NotFound from './Pages/NotFound';

// Dashboard
import Dashboard from './Pages/Dashboard';

// Login
import Login from './Pages/Login';

// Configuration
import Configuration from './Pages/Configuration';
import GammePage from './Pages/Configuration/Gamme';
import TypePage from './Pages/Configuration/Type';
import CategoryPage from './Pages/Configuration/Category';
import RightPage from './Pages/Configuration/Right';
import SetRight from './Pages/Configuration/SetRight';

// Ressource
import RessourcePage from './Pages/Ressource';

// Agent
import Agent from './Pages/Agent';
import AgentList from './Pages/Agent/list';
import AgentCreate from './Pages/Agent/create';
import AgentEdit from './Pages/Agent/edit';

// Product
import Product from './Pages/Product';
import ProductList from './Pages/Product/list';
import ProductCreate from './Pages/Product/create';
import ProductEdit from './Pages/Product/edit';

// Client
import ClientPage from './Pages/Client';
import ClientList from './Pages/Client/list';
import ClientCreate from './Pages/Client/create';
import ClientEdit from './Pages/Client/edit'; 

// Shop
import ShopPage from './Pages/Shop';
import ShopList from './Pages/Shop/list';
import ShopCreate from './Pages/Shop/create';
import ShopEdit from './Pages/Shop/edit';

// Approvisonnement
import ApprovPage from './Pages/Approv';
import ApprovList from './Pages/Approv/list';
import ApprovCreate from './Pages/Approv/create';

// Inventaire 
import InventairePage from './Pages/Inventaire';
import InventaireList from './Pages/Inventaire/list';
import InventaireCreate from './Pages/Inventaire/create';

// Vente
import VentePage from './Pages/Vente';
import VenteList from './Pages/Vente/list';
import VenteCreate from './Pages/Vente/create';

// Salary
import SalaryTypePage from './Pages/Salary/type';
import SalaryPage from './Pages/Salary';
import SalaryPayPage from './Pages/Salary/pay';
import SupervizeSalaryList from './Pages/Salary/supervize';

// Spent
import SpentPage from './Pages/Spent';
import SpentTypePage from './Pages/Spent/type';
import SpentList from './Pages/Spent/list';
import SpentCreate from './Pages/Spent/create';
import SpentDetail from './Pages/Spent/detail';
import ComptaPage from './Pages/Compta';
import ComptaVentes from './Pages/Compta/ventes';
import ComptaResult from './Pages/Compta/result';
import HistoryPage from './Pages/History';
import SchedulePage from './Pages/Schedule';
import InterestRepartitionPage from './Pages/InterestRepartition';
import AssociatesPage from './Pages/InterestRepartition/associates';
import WithdrawalPage from './Pages/InterestRepartition/withdrawal';
import SupervizeWithdrawalList from './Pages/InterestRepartition/supervize';
import TaxePage from './Pages/Taxe';
import TaxePaymentPage from './Pages/Taxe/payment';
import TaxeReportPage from './Pages/Taxe/report';
import TaxeRaportResultPage from './Pages/Taxe/result';
import PromotionPage from './Pages/Promotion';
import PromotionList from './Pages/Promotion/list';
import PromotionCreate from './Pages/Promotion/create';
import PromotionEdit from './Pages/Promotion/edit';
import ReductionPage from './Pages/Reduction';
import ReductionList from './Pages/Reduction/list';
import ReductionCreate from './Pages/Reduction/create';
import ReductionEdit from './Pages/Reduction/edit';
import TemplatePage from './Pages/Template';
import TemplateList from './Pages/Template/list';
import TemplateCreate from './Pages/Template/create';
import SendMailPage from './Pages/Template/send-mail';
import PrintFlyersPage from './Pages/Template/print-flyers';
import TemplateEdit from './Pages/Template/edit';
import AmortizmentPage from './Pages/Amortizment';
import AmortizmentList from './Pages/Amortizment/list';
import AmortizmentCreate from './Pages/Amortizment/create';
import TransportPage from './Pages/Transport';
import TransportList from './Pages/Transport/list';
import TransportCreate from './Pages/Transport/create';
import TransportEdit from './Pages/Transport/edit';
import MessagePage from './Pages/Message';
import MessageList from './Pages/Message/list';
import MessageCreate from './Pages/Message/create';
import MessageChat from './Pages/Message/chat';
import NotificationList from './Pages/Notification/list';
import NotificationPage from './Pages/Notification';


import ProductKitList from './Pages/Product/Kit/list';
import ProductKitCreate from './Pages/Product/Kit/create';
import AnalyticPage from './Pages/Analytic';
import AnalyticFormPage from './Pages/Analytic/form';
import AnalyticResultPage from './Pages/Analytic/result';
import BillPage from './Pages/Spent/bill';
import InconstructionPage from './Pages/InConstruction';
import ProductKitEdit from './Pages/Product/Kit/edit';
import WebVenteList from './Pages/Vente/web-list';
import WebVenteDetails from './Pages/Vente/web-vente-details';

//


// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
type ProtectedRouteProps = { 
  outlet: JSX.Element;
};

function ProtectedRoute({ outlet }: ProtectedRouteProps) {
  const dispatch = useAppDispatch();
  const user = window.localStorage.getItem('app_user');
  if( user !== null && user !== undefined ) {
    dispatch( setUser( User.fromJson(  JSON.parse(user)  ) ) ); 
    return outlet;
  } else {
    window.location.href = "/auth"
    return <Navigate to={{ pathname: '/auth' }}  replace={true} />;
  }
};

// const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
//   isAuthenticated: true,
//   authenticationPath: '/auth',
// };

const App = () => {

  return (
    // basename="/poten"
    
      <Router  >
        <Track/>
        
        <Routes >
          <Route path="/" element={ <Admin />} >

            <Route index element={<ProtectedRoute  outlet={ <Dashboard />} />} />

            {/* Product */}
            <Route path="product" element={<ProtectedRoute  outlet={ <Product />} />} >
              <Route index element={<ProtectedRoute  outlet={ <ProductList />} />} />
              <Route path="list" element={<ProtectedRoute  outlet={ <ProductList />} />} />
              <Route path="create" element={<ProtectedRoute  outlet={ <ProductCreate  />} />} />
              <Route path="edit/:id" element={<ProtectedRoute  outlet={ <ProductEdit />} />} />
              <Route path="kit/list" element={<ProtectedRoute  outlet={ <ProductKitList />} />} />
              <Route path="kit/create" element={<ProtectedRoute  outlet={ <ProductKitCreate />} />} />
              <Route path="kit/edit/:id" element={<ProtectedRoute  outlet={ <ProductKitEdit />} />} />
            </Route>

            {/* Boutique */}
            <Route path="boutique" element={<ProtectedRoute  outlet={ <ShopPage />} />} >
              <Route index element={<ProtectedRoute  outlet={ <ShopList />} />} />
              <Route path="list" element={<ProtectedRoute  outlet={ <ShopList />} />} />
              <Route path="create" element={<ProtectedRoute  outlet={ <ShopCreate />} />} />
              <Route path="edit/:id" element={<ProtectedRoute  outlet={ <ShopEdit />} />} />
            </Route>


            {/* Agent Menu */}
            <Route path="agent" element={<ProtectedRoute  outlet={ <Agent />} />} >
              <Route index element={<ProtectedRoute  outlet={ <AgentList />} />} />
              <Route path="list" element={<ProtectedRoute  outlet={ <AgentList />} />} />
              <Route path="create" element={<ProtectedRoute  outlet={ <AgentCreate  />} />} />
              <Route path="edit/:id" element={<ProtectedRoute  outlet={ <AgentEdit />} />} />
            </Route>


            {/* Client Menu */}
            <Route path="client" element={<ProtectedRoute  outlet={ <ClientPage />} />} >
              <Route index element={<ProtectedRoute  outlet={ <ClientList />} />} />
              <Route path="list" element={<ProtectedRoute  outlet={ <ClientList />} />} />
              <Route path="create" element={<ProtectedRoute  outlet={ <ClientCreate  />} />} />
              <Route path="edit/:id" element={<ProtectedRoute  outlet={ <ClientEdit />} />} />
            </Route>


            {/* Approvisonnement Menu */}
            <Route path="approvisionnement" element={<ProtectedRoute  outlet={ <ApprovPage />} />} >
              <Route index element={<ProtectedRoute  outlet={ <ApprovList />} />} />
              <Route path="list" element={<ProtectedRoute  outlet={ <ApprovList />} />} /> 
              <Route path="create" element={<ProtectedRoute  outlet={ <ApprovCreate />} />} /> 
            </Route>


            {/* Inventaire Menu */}
            <Route path="inventaire" element={<ProtectedRoute  outlet={ <InventairePage />} />} >
              <Route index element={<ProtectedRoute  outlet={ <InventaireList />} />} />
              <Route path="list" element={<ProtectedRoute  outlet={ <InventaireList />} />} />  
              <Route path="create/:type" element={<ProtectedRoute  outlet={ <InventaireCreate  />} />} />
            </Route>

            {/* Vente Menu */}
            <Route path="vente" element={<ProtectedRoute  outlet={ <VentePage  />} />} >
              <Route index element={<ProtectedRoute  outlet={ <VenteList />} />} />
              <Route path="list" element={<ProtectedRoute  outlet={ <VenteList />} />} />  
              <Route path="create/:type" element={<ProtectedRoute  outlet={ <VenteCreate  />} />} />
              <Route path="web" element={<ProtectedRoute  outlet={ <WebVenteList />} />} />  
              <Route path="web/:id" element={<ProtectedRoute  outlet={ <WebVenteDetails />} />} />  
            </Route>

            {/* Configuration Menu */}
            <Route path="configuration" element={<ProtectedRoute  outlet={ <Configuration />} />} >
              <Route index element={<ProtectedRoute  outlet={ <GammePage />} />} />
              <Route path="gamme" element={<ProtectedRoute  outlet={ <GammePage />} />} /> 
              <Route path="type" element={<ProtectedRoute  outlet={ <TypePage  />} />} /> 
              <Route path="category" element={<ProtectedRoute  outlet={ <CategoryPage  />} />} /> 
              <Route path="droit" element={<ProtectedRoute  outlet={ <RightPage />} />} /> 
              <Route path="attribuer-droit" element={<ProtectedRoute  outlet={ <SetRight />} />} /> 
            </Route>


            {/* Salary Menu */}
            <Route path="salaire" element={<ProtectedRoute  outlet={ <SalaryPage  />} />} >
              <Route index element={<ProtectedRoute  outlet={ <SalaryTypePage />} />} />
              <Route path="type" element={<ProtectedRoute  outlet={ <SalaryTypePage />} />} /> 
              <Route path="payer" element={<ProtectedRoute  outlet={ <SalaryPayPage  />} />} />  
              <Route path="supervises" element={<ProtectedRoute  outlet={ <SupervizeSalaryList  />} />} />  
            </Route>

            {/* Spent Menu */}
            <Route path="depense" element={<ProtectedRoute  outlet={ <SpentPage  />} />} >
              <Route index element={<ProtectedRoute  outlet={ <SpentTypePage />} />} />
              <Route path="type" element={<ProtectedRoute  outlet={ <SpentTypePage />} />} /> 
              <Route path="list" element={<ProtectedRoute  outlet={ <SpentList />} />} /> 
              <Route path="create" element={<ProtectedRoute  outlet={ <SpentCreate />} />} /> 
              <Route path="details/:id" element={<ProtectedRoute  outlet={ <SpentDetail  />} />} />
              <Route path="bill" element={<ProtectedRoute  outlet={ <BillPage />} />} /> 
            </Route>

             {/* Dépense Menu */}
             <Route path="compta" element={<ProtectedRoute  outlet={ <ComptaPage  />} />} >
              <Route index element={<ProtectedRoute  outlet={ <ComptaVentes />} />} />
              <Route path="ventes" element={<ProtectedRoute  outlet={ <ComptaVentes />} />} /> 
              <Route path="result" element={<ProtectedRoute  outlet={ <ComptaResult />} />} /> 
            </Route>

            {/* History Menu */}
            <Route path="historique" element={<ProtectedRoute  outlet={ <HistoryPage />} />} />

            {/* Schedule Menuy */}
            <Route path="horaires" element={<ProtectedRoute  outlet={ <SchedulePage />} />} />

            {/* Interest Menu */}
            <Route path="repartition-interet" element={<ProtectedRoute  outlet={ <InterestRepartitionPage   />} />} >
              <Route index element={<ProtectedRoute  outlet={ <AssociatesPage />} />} />
              <Route path="associes" element={<ProtectedRoute  outlet={ <AssociatesPage />} />} />  
              <Route path="retrait" element={<ProtectedRoute  outlet={ <WithdrawalPage />} />} />  
              <Route path="retrait-supervises" element={<ProtectedRoute  outlet={ <SupervizeWithdrawalList />} />} /> 
            </Route>

            {/* Taxe Menu */}
            <Route path="impots" element={<ProtectedRoute  outlet={ <TaxePage   />} />} >
              <Route index element={<ProtectedRoute  outlet={ <TaxePaymentPage />} />} />
              <Route path="payement" element={<ProtectedRoute  outlet={ <TaxePaymentPage />} />} />   
              <Route path="rapport" element={<ProtectedRoute  outlet={ <TaxeReportPage />} />} /> 
              <Route path="rapport-result" element={<ProtectedRoute  outlet={ <TaxeRaportResultPage  />} />} /> 
            </Route>


            {/* Promotion Menu */}
            <Route path="promotion" element={<ProtectedRoute  outlet={ <PromotionPage   />} />} >
              <Route index element={<ProtectedRoute  outlet={ <PromotionList />} />} />
              <Route path="list" element={<ProtectedRoute  outlet={ <PromotionList />} />} />   
              <Route path="create" element={<ProtectedRoute  outlet={ <PromotionCreate />} />} />   
              <Route path="edit/:id" element={<ProtectedRoute  outlet={ <PromotionEdit />} />} />
            </Route>


            {/* Reduction Menu */}
            <Route path="reduction" element={<ProtectedRoute  outlet={ <ReductionPage   />} />} >
              <Route index element={<ProtectedRoute  outlet={ <ReductionList />} />} />
              <Route path="list" element={<ProtectedRoute  outlet={ <ReductionList />} />} />   
              <Route path="create" element={<ProtectedRoute  outlet={ <ReductionCreate />} />} />   
              <Route path="edit/:id" element={<ProtectedRoute  outlet={ <ReductionEdit />} />} />
            </Route>


            {/* Reduction Menu */}
            <Route path="template" element={<ProtectedRoute  outlet={ <TemplatePage   />} />} >
                <Route index element={<ProtectedRoute  outlet={ <TemplateList />} />} />
                <Route path="list" element={<ProtectedRoute  outlet={ <TemplateList />} />} />   
                <Route path="create" element={<ProtectedRoute  outlet={ <TemplateCreate />} />} /> 
                <Route path="edit/:id" element={<ProtectedRoute  outlet={ <TemplateEdit />} />} />  
                <Route path="send-mail" element={<ProtectedRoute  outlet={ <SendMailPage />} />} /> 
                <Route path="imprimer-flyers" element={<ProtectedRoute  outlet={ <PrintFlyersPage />} />} /> 
            </Route>


            {/* Amortizment Menu */}
            <Route path="amortissement" element={<ProtectedRoute  outlet={ <AmortizmentPage   />} />} >
                <Route index element={<ProtectedRoute  outlet={ <AmortizmentList />} />} />
                <Route path="list" element={<ProtectedRoute  outlet={ <AmortizmentList />} />} />   
                <Route path="create" element={<ProtectedRoute  outlet={ <AmortizmentCreate />} />} /> 
                {/* 
                <Route path="edit/:id" element={<ProtectedRoute  outlet={ <TemplateEdit />} />} />  
                <Route path="send-mail" element={<ProtectedRoute  outlet={ <SendMailPage />} />} /> 
                <Route path="print-flyers" element={<ProtectedRoute  outlet={ <PrintFlyersPage />} />} />  */}
            </Route>

            {/* Transport Menu */}
            <Route path="transport" element={<ProtectedRoute  outlet={ <TransportPage   />} />} >
                <Route index element={<ProtectedRoute  outlet={ <TransportList />} />} />
                <Route path="list" element={<ProtectedRoute  outlet={ <TransportList />} />} />   
                <Route path="create" element={<ProtectedRoute  outlet={ <TransportCreate />} />} /> 
                <Route path="edit/:id" element={<ProtectedRoute  outlet={ <TransportEdit />} />} /> 
            </Route>

            
            {/* Message Menu */}
            <Route path="message" element={<ProtectedRoute  outlet={ <MessagePage   />} />} >
                <Route index element={<ProtectedRoute  outlet={ <MessageList />} />} />
                <Route path="list" element={<ProtectedRoute  outlet={ <MessageList />} />} />
                <Route path="create" element={<ProtectedRoute  outlet={ <MessageCreate />} />} /> 
                <Route path="chat/:idReceiver/:idChat" element={<ProtectedRoute  outlet={ <MessageChat />} />} /> 
            </Route>

            {/* Notification Menu */}
            <Route path="notification" element={<ProtectedRoute  outlet={ <NotificationPage />} />} >
                <Route index element={<ProtectedRoute  outlet={ <NotificationList />} />} />
                <Route path="list" element={<ProtectedRoute  outlet={ <NotificationList />} />} /> 
            </Route>

            {/* Analytic Menu */}
            <Route path="analytics" element={<ProtectedRoute  outlet={ <AnalyticPage />} />} >
                <Route index element={<ProtectedRoute  outlet={ <AnalyticFormPage />} />} />
                <Route path="results" element={<ProtectedRoute  outlet={ <AnalyticResultPage />} />} /> 
            </Route>


            {/* Ressource */}
            <Route path="ressource" element={<ProtectedRoute  outlet={ <RessourcePage />} />} />

            
            <Route path="investissements" element={<ProtectedRoute  outlet={ <InconstructionPage />} />} />
            <Route path="concentration&ingredient" element={<ProtectedRoute  outlet={ <InconstructionPage />} />} />
            <Route path="declaration&assurances" element={<ProtectedRoute  outlet={ <InconstructionPage />} />} />
            <Route path="aide&action" element={<ProtectedRoute  outlet={ <InconstructionPage />} />} />
            <Route path="camera" element={<ProtectedRoute  outlet={ <InconstructionPage />} />} />
            <Route path="repertoire" element={<ProtectedRoute  outlet={ <InconstructionPage />} />} />
            <Route path="reference" element={<ProtectedRoute  outlet={ <InconstructionPage />} />} />
          </Route>  
          <Route path="/auth" element={ <Auth />} >
            <Route index element={<Login />} />
          </Route> 
          <Route path="*" element={ <NotFound />} />
        </Routes>
      </Router> 
  );
}

export default  App;

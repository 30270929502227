import { FC, useEffect, useState, useTransition } from 'react'
import DataTable from 'react-data-table-component';
import Breadcrumb from '../../Components/Breadcrumb';
import VenteService from './service'; 

//Moment react
import Moment from 'react-moment';

import {
    Link, useNavigate,
    useParams
} from "react-router-dom";
import { useTranslation } from 'react-i18next';

const WebVenteDetails: FC = () => {
      
    const { id } = useParams(); 
    let idToModInit: any = null;
    const [ order, setOrder ] = useState( idToModInit );
    const [ idToMod, setIdToMod ] = useState(idToModInit);
    const [ loading, setLoading ] = useState(false);
    const venteService = new VenteService();

    const navigate = useNavigate();

    const { t } = useTranslation();

    let editAgent = (id: Number): void => {
        setIdToMod(id);
        setLoading(true);
        navigate('/agent/edit/'+id.toString());
        setLoading(false);
    }

    const getWebVenteDetails = () => {
        setLoading(true);
        venteService.geWebVenteDetails( Number(id) ).then(async function (response: any) {
            console.log(response);
            setOrder(response.data.data);
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
    }

    const toNumber = (val: any) => {
        return Number(val);
    }

    const getOrderStatus = (val: string) => {
        switch(val) {
            case 'paid':
              return 'Payée';
              break;
            case 'processing':
              return 'En cours de traitement';
              break;
            case 'processed':
                return 'En attente d\'expédition';
                break;
            case 'shipped':
               return 'Expédiée en cours de livraison';
               break;
            case 'delivered':
                return 'Livrée';
                break;
            default:
              return val;
          }
    }

 

    const columns = [
        {
            name: 'Produit',
            selector: (row: any) => row.produit
        },
        {
            name: 'Montant',
            selector: (row: any) => row.montant,
        },
        {
            name: 'Prix unitaire',
            selector: (row: any) => row.pu,
        },
        {
            name: 'Quantité',
            selector: (row: any) => row.qte,
        } 
    ];

    const updateOrderStatus = (orderStatus: string) => {
       const confirmation = window.confirm("Etes vous sûre de mettre jour la commande ?");
       if (confirmation) {
        setLoading(true);
        venteService.updateOrderStatus( Number(order.id), orderStatus ).then(async function (response: any) {
            console.log(response);
            getWebVenteDetails();
            setLoading(false); 
        })
          .catch(function (error: any) {
            console.log(error);
            setLoading(false); 
        });
       }
    }
    


    useEffect(function() { 
        getWebVenteDetails();
    }, []); 

    return (
            <> 
                <Breadcrumb />
 
                <div className="container-fluid">

                <div className="btn-list">
                                        <button type="button" onClick={ event => navigate('/vente/web') }
                                        className="btn waves-effect waves-light btn-danger">
                                            { t('Retour') }
                                        </button>
                                       
                                    </div>
                                    <br/>
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">
                            {
                                t('Mettre à jour la commande')
                            }
                        </h4>
                        { order !== null && <div className="btn-list">
                            <button type="button" 
                                onClick={event =>  updateOrderStatus('processing')  }
                                className="btn waves-effect waves-light btn-success">
                                    {
                                        t('En traitement')
                                    }
                            </button>
                            <button type="button" disabled={ order.orderStatut.libelle === 'paid' }
                                onClick={event => updateOrderStatus('processed') }
                                className="btn waves-effect waves-light btn-info">
                                    {
                                        t('Prêt pour expédition')
                                    }
                                </button>
                            <button type="button"  disabled={ order.orderStatut.libelle === 'paid' || order.orderStatut.libelle === 'processing' }
                                onClick={event => updateOrderStatus('shipped') }
                                className="btn waves-effect waves-light btn-warning">
                                    {
                                        t('Expédier')
                                    }
                                </button>
                            <button type="button"  disabled={ order.orderStatut.libelle === 'paid' || 
                                order.orderStatut.libelle === 'processing' || order.orderStatut.libelle === 'processed' 
                                
                            }
                                onClick={event => updateOrderStatus('delivered') }
                                className="btn waves-effect waves-light btn-danger">
                                    {
                                        t('Livrer')
                                    }
                            </button>  
                        </div> }
                    </div>
                </div>        
                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-body">
                                    
                                    
                                    <br />
                                    <br /> 
                                    {  order !== null && <h3 >
                                        <b style={{ textDecoration: 'underline', color: '#c4c850' }} >ID COMMANDE: </b> <b>{ order.id }</b>
                                    </h3> }
                                    <br/>  
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <h3 style={{ textDecoration: 'underline', color: '#c4c850' }} className="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox"> 
                                                <b>Client</b>
                                            </h3>
                                            <div>
                                            {  order !== null && <ul style={{ listStyleType: 'none', width: '100%' }} >
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Prénoms :</b> 
                                                            { order.vente.client.prenomClient}
                                                        </li>
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Nom :</b> { order.vente.client.nomClient}
                                                        </li>
                                                        <br />
                                                        <br />
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Email :</b> { order.vente.client.emailClient }
                                                        </li>
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Contact :</b> { order.vente.client.contactClient }
                                                        </li>
                                                        <br />
                                                        <br />
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Pays :</b> { order.vente.client.pays.name }
                                                        </li>
                                                    </ul> }  
                                            </div>
                                        </div>
                                        <div className='col-md-6'>

                                        <h3 style={{ textDecoration: 'underline', color: '#c4c850' }} className="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox"> 
                                                <b>Commande</b>
                                            </h3>

                                        {  order !== null && <ul style={{ listStyleType: 'none', width: '100%' }} >
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Statut commande :</b> 
                                                            { ' ' + getOrderStatus(order.orderStatut.libelle) }
                                                        </li>
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Date de la commande :</b>    <Moment format="YYYY/MM/DD hh:mm:ss">
                                                                                                {order.addDate}
                                                                                            </Moment>
                                                        </li>
                                                        <br />
                                                        <br />
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Methode de paiement :</b>  {' ' + order.paymenentMethod.libelle}  
                                                        </li>
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Montant payé :</b>  { toNumber(order.vente.montantVente) +
            toNumber(order.shippingFees) + toNumber(order.taxesFees) - toNumber(order.montantReduction)  } USD 
                                                        </li>
                                                        <br />
                                                        <br />
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Frais de livraison :</b> : { order.shippingFees } USD
                                                        </li>
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Frais taxe boutique :</b> : { order.taxesFees } USD
                                                        </li>
                                                    </ul> }  
                                            
                                        </div>
                                    </div>   
                                    <br/>                            
                                    <div className='row'>
                                        <div className='col-md-6'> 
                                                <h3 style={{ textDecoration: 'underline', color: '#c4c850' }}   className="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox"> 
                                                    <b>Adresse d'expédition</b>
                                                </h3>
                                           

                                            <div className="woocommerce-additional-fields__field-wrapper">
                                            
                                                {  order !== null && <ul style={{ listStyleType: 'none', width: '100%' }} >
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Pays :</b> 
                                                            { order.shippingAddress.country }
                                                        </li>
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Région :</b> { order.shippingAddress.stateOrProvince }
                                                        </li>
                                                        <br />
                                                        <br />
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Ville :</b> { order.shippingAddress.city }
                                                        </li>
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Code postale :</b> { order.shippingAddress.zipOrPostalCode }
                                                        </li>
                                                        <br />
                                                        <br />
                                                        <li style={{ width: '49%', display: 'inline-block' }} >
                                                            <b>Addresse :</b> { order.shippingAddress.addresseLineOne }
                                                        </li>
                                                    </ul> }  
                                            </div>

                                        </div>
                                        <div className='col-md-6'>

                                        <div className="woocommerce-shipping-fields">
	
                                            <h3 style={{ textDecoration: 'underline', color: '#c4c850' }}  id="ship-to-different-address">
                                                <b className="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox"> 
                                                    <span>Adresse de livraion</span>
                                                </b>
                                            </h3> 

                                        </div>

                                        <div className="woocommerce-additional-fields__field-wrapper">
                        
                                        {  order !== null && <ul style={{ listStyleType: 'none', width: '100%' }} >
                                                <li style={{ width: '49%', display: 'inline-block' }} >
                                                    <b>Pays :</b> 
                                                    { order.deliveryAddress.country }
                                                </li>
                                                <li style={{ width: '49%', display: 'inline-block' }} >
                                                    <b>Région :</b> { order.deliveryAddress.stateOrProvince }
                                                </li>
                                                <br />
                                                <br />
                                                <li style={{ width: '49%', display: 'inline-block' }} >
                                                    <b>Ville :</b> { order.deliveryAddress.city }
                                                </li>
                                                <li style={{ width: '49%', display: 'inline-block' }} >
                                                    <b>Code postale :</b> { order.deliveryAddress.zipOrPostalCode }
                                                </li>
                                                <br />
                                                <br />
                                                <li style={{ width: '49%', display: 'inline-block' }} >
                                                    <b >Addresse :</b> { order.deliveryAddress.addresseLineOne }
                                                </li>
                                            </ul> }  
                                        </div>

                                        </div>
                                    </div>

                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <h3 style={{ textDecoration: 'underline', color: '#c4c850' }}  id="ship-to-different-address">
                                                <b className="woocommerce-form__label woocommerce-form__label-for-checkbox checkbox"> 
                                                    <span>Les Produits</span>
                                                </b>
                                            </h3>
                                            <br /> 
                                            <div className="table-responsive">
                                               {
                                                order !== null ? order.content !== null ?
                                                    <DataTable
                                                    responsive={true}
                                                    className="table table-striped table-bordered"
                                                    columns={columns}
                                                    data={ order.content }
                                                    progressPending={loading}
                                                    pagination
                                                />: <></> : <></>
                                               }
                                            </div>
                                        </div>
                                    </div>

                                    </div>
                                </div> 
                        </div> 
                    </div>

                </div>   
            </>                   

    );
}

export default WebVenteDetails
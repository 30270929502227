import User from '../../Models/User';
import axiosInstance from '../../Utils/axios_manager';

export default class VenteService {
  
    constructor( ) {
    }

    public postClient =  (user: User) => {
        return axiosInstance.post('/client/add', User.toJson(user));
    }

    public getVentes =  async () => {
        return await axiosInstance.get('/panier/list');
    }

    public geWebVentes =  async () => {
        return await axiosInstance.get('/order/listAll');
    }

    public geWebVenteDetails =  async (id: Number) => { 
            return await axiosInstance.get('/order/details' + `?id=${id.toString()}`);
    }

    public getClient =  async (id: Number) => {
        return await axiosInstance.get('/client/details' + `?id=${id.toString()}`);
    }

    public putClient =  (user: User) => {
        return axiosInstance.post('/client/update', User.toJson(user));
    }

    public saveApprov =  (approv: Record<string, any>) => {
        return axiosInstance.post('/approv/add', approv);
    }

    public getProductByBarcode = (params: Record<string, any>) => {
        return axiosInstance.get('/produit/list/barcode/filtred/simple', {
            params: params
        });
    }

    public updateOrderStatus =  async (orderId : Number, orderStatus: string) => { 
        return await axiosInstance.get('/order/statusUpdate' + `?orderId=${orderId.toString()}`+ `&orderStatus=${orderStatus}`);
    }
  

}